import React, { useState, useEffect } from 'react';
import '../css/Landing.css';

function Landing() {
  const [text, setText] = useState("");
  const message = "The next-gen material science digital assistant.";
  const [text2, setText2] = useState("");
  const message2 = "Email us at:";
  const [text3, setText3] = useState("");
  const message3 = "info@fum.tech";

  useEffect(() => {
    if (text !== message) {
      setTimeout(() => {
        setText(message.substr(0, text.length + 1));
      }, 70);
    }
  }, [message, text, setText]);

  useEffect(() => {
    if (text === message && text2 !== message2) {
      setTimeout(() => {
        setText2(message2.substr(0, text2.length + 1));
      }, 70);
    }
  }, [message, text, message2, text2, setText2]);

  useEffect(() => {
    if (text === message && text2 === message2 && text3 !== message3) {
      setTimeout(() => {
        setText3(message3.substr(0, text3.length + 1));
      }, 70);
    }
  }, [message, text, message2, text2, message3, text3, setText3]);


  return (
    <div className="Landing">
      <header className="Landing-header">
        <h1>Fum</h1>
        <div className="typewriter-text">{text}</div>
        <div className="typewriter-text">{text2} <a href="mailto:info@fum.tech">{text3}</a></div>
      </header>
    </div>
  );
}

export default Landing;
